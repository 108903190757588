/* You can add global styles to this file, and also import other style files */

// html, body { height: 100%; }
// * {font-family: Lato,"Helvetica Neue", sans-serif; }

// .custom-mat-form-field.mat-form-field-appearance-outline {
//     width: 100%;

//     .mat-mdc-form-field-infix {
//         top: -5px;
//         padding: 10px 0;
//     }

//     .mat-form-field-outline, .mat-mdc-form-field-flex {
//         height: 30px;
//     }

//     .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
//         border-color: transparent;
//         background-color: #F4F4F4;
//         opacity: 0.5;
//         border-radius: 25px;
//     }

//     .mdc-notched-outline__leading {
//         display: none;
//     }
// }
// .mdc-line-ripple{
//     display: none;
//   }
*{
  box-sizing: border-box;
}
body,html {
  margin: 0 auto;
  padding: 0;
  background-color: #fbfbfb;
}
input,
select {
  width: 100%;
  height: 36px;
  background-color: #f4f4f4;
  border-radius: 18px;
  border: none;
  padding-left: 15px;
  margin-top: 0px;
}
input {
  padding-right: 15px;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("./assets/icons/triangle.svg");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: center;
  padding-right: 0px;
}
mat-select {
  width: 100%;
  height: 36px;
  left: 0px;
  top: 33px;
  background-color: #f4f4f4;
  border-radius: 18px;
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 10px;
}

.mat-mdc-select-value {
  margin-top: 7px !important;
}
.mat-mdc-select {
  width: 98% !important;
}
.mdc-notched-outline {
  display: none !important;
}

.gapped-row {
  margin-top: 20px;
}
.subHeading {
  height: 17px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  white-space: nowrap;
}

.mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
  border-top-width: 4px !important;
  border-radius: 2px 2px 0px 0px !important;
  border-color: #0066b0 !important;
}
.mdc-tab__text-label {
  font-weight: 700;
  // font-size: 20px;
  line-height: 24px;
}
.mdc-tab--active {
  .mdc-tab__text-label {
    color: #282828 !important;
  }
}

.mat-mdc-snack-bar-container,
.mdc-snackbar__surface {
  border-radius: 18px !important;
  background-color: #cdffd8 !important;
  box-shadow: none !important;
  width: 478px;
  height: 36px;
}
.mat-mdc-snack-bar-label {
  color: #089327 !important;
  font-weight: 400 !important;
  text-align: center !important;
}

.mdc-line-ripple {
  display: none;
}
.mat-mdc-text-field-wrapper {
  border-radius: 18px !important;
  height: 38px !important;
  display: flex !important;
  align-items: center !important;
}
.mat-mdc-form-field-infix {
  padding-top: 8px !important;
  padding-bottom: 0px !important;
}
mat-label {
  font-size: 14px;
  
}
.mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}
.mat-datepicker-input {
  font-size: 14px !important;
  width: fit-content !important;
}
.mdc-floating-label--float-above {
  // display: none;
  transform: translateY(-106%) scale(0) !important;
}

.star {
  color: red;
}
.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: white;
}
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
  ~ .mdc-checkbox__background {
  background-color: #0066b0 !important;
  border-color: #0066b0 !important;
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-icon-color: #0066b0 !important;
  --mdc-radio-selected-hover-icon-color: #0066b0 !important;
}
.mat-pseudo-checkbox-checked {
  background-color: #0066b0 !important;
}
table {
  tr {
    td {
      font-size: 13px;
    }
    th {
      font-size: 14px;
    }
  }
}

.mat-mdc-option .mdc-list-item__primary-text {
  font-size: 14px !important;
}
.mat-calendar-body-cell {
  // position: absolute !important;
  height: 100% !important;
}
.side{
  color: #de673e;
  border: #fbfbfb;
  background-color: white;
}
.even{
  display: flex;
  justify-content: space-between;
}

a{
  text-decoration: none;
  color: #F28021;
}

.loader-btn{
  position: relative;
}

.loader-btn::after{
  z-index: 10;
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  background: url('https://cdn.slidevision.io/www/14304000000085015_loader.gif') no-repeat center/cover, #f7f2f2;
}

.custom-card{
  box-shadow: 0 0 8px 0 rgba(211, 211, 211, 0.5);
  padding: 1rem;
  border-radius: 0.9rem;
  margin-top: 0.5rem;
}

.sidebar{
  a{
    white-space: unset !important;
  }
}
.disable{
  position: relative;
  &::after{
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      background: transparent;
      z-index: 1;
      border-radius: 25px;
      cursor: not-allowed;
  }
  .disable__override{
      position: relative;
      z-index: 5;
  }
}